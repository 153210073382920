/* eslint-disable tailwindcss/classnames-order */
// TODO: remove after migrating to tailwind
import React, { FC, useEffect, useRef, useState } from 'react';
import LinkedinIcon from '@/assets/icons/linkedin.inline.svg';
import Button from '../../Button/Button';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import AnimatedImage from '../../AnimatedImage/AnimatedImage';
import { Parallax } from 'react-scroll-parallax';

import './Partner.scss';

gsap.registerPlugin(ScrollTrigger);

export type PartnerProps = {
  id: number;
  name: string;
  position: string;
  description: string;
  image: string;
  isImageRight: boolean;
  linkedin?: string;
};

const Partner: FC<PartnerProps> = (props) => {
  const { name, position, description, image, isImageRight, id, linkedin } = props;
  const [isUnderView, setIsUnderView] = useState(false);
  const partnerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!partnerRef.current) return;

    const startViewport = (window.innerHeight * 3) / 4;
    const startTrigger = -(partnerRef.current?.clientHeight * 0.6);

    gsap.timeline({
      scrollTrigger: {
        trigger: `.partner--${id}`,
        start: `${startTrigger} ${startViewport}`,
        scrub: 1,
        onEnter: () => {
          setIsUnderView(true);
        },
      },
    });

    ScrollTrigger.create({
      trigger: partnerRef.current,
      start: `${startTrigger} ${startViewport}`,
      end: `${startTrigger} ${startViewport}`,
      onEnter: () => {
        partnerRef.current?.classList.add('animation-play');
      },
      once: true,
    });
  }, []);

  return (
    <div className={`partner partner--${id} animated fadeInUp`} ref={partnerRef}>
      <div
        className={`partner__wrapper partner__wrapper${isImageRight ? '--right-side-image' : ''}`}
      >
        <Parallax speed={-7} className="partner__parallax">
          <div
            className={`partner__image-wrapper partner__image-wrapper${
              isImageRight ? '--right-side-image' : ''
            }`}
          >
            <AnimatedImage imageAlt="partner1" imageUrl={image} isActive={isUnderView} />
          </div>
        </Parallax>
        <div className="partner__text-wrapper">
          <h2 className="partner__name">{name} /</h2>
          <p className="partner__position">{position}</p>
          <p className="partner__description" dangerouslySetInnerHTML={{ __html: description }} />
          <Button className="partner__linkedin-icon" tag="link" href={linkedin} target="_blank">
            <LinkedinIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Partner;
