import React, { useEffect, useState, useRef, useContext } from 'react';
import { AppContext } from '../../store/context';
import { Media } from '@/components/shared/Media/Media';
import Rarrow from '@/assets/icons/shape_arrow.svg';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import AnimateText from '@/components/shared/AnimateText/AnimateText';
import useTriggerLightTheme from '../../hooks/useTriggerLightTheme';

import './AboutPhrase.scss';

gsap.registerPlugin(ScrollTrigger);

type AboutPhrase = {
  text: string;
  description: string;
};

const AboutPhrase = ({ text, description }: AboutPhrase) => {
  const { navbarHeight } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const phraseRef = useRef<HTMLElement>(null);
  const animationOffset = navbarHeight / 2 + 10;

  useTriggerLightTheme(phraseRef.current, animationOffset, animationOffset);

  useEffect(() => {
    gsap.to(phraseRef.current, {
      scrollTrigger: {
        trigger: phraseRef.current,
        start: '30% bottom',
        onToggle: (self) => {
          if (self.isActive) {
            document.querySelector('.phrase__arrow')?.classList.add('animation-play');
            setIsLoading(false);
          }
        },
      },
    });
  }, []);

  return (
    <section ref={phraseRef} className="about-phrase">
      <div className="about-phrase__wrapper">
        <Media
          source={Rarrow}
          className="about-phrase__arrow"
          alt="arrow"
          noPicture={true}
          hidden={true}
        />
        {!isLoading ? (
          <div className="about-phrase__content">
            <h2 className={`about-phrase__text`}>
              <AnimateText text={text} trigger={'.about-phrase'} />
            </h2>
            {description ? (
              <p className="about-phrase__description">
                <AnimateText
                  text={description}
                  trigger={'.about-phrase'}
                  animationStart={'30% top'}
                />
              </p>
            ) : null}
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default AboutPhrase;
export type { AboutPhrase };
