import React from 'react';
import { ParallaxBanner, EffectNumber } from 'react-scroll-parallax';
import Button from '@/components/shared/Button/Button';

import './GenericHero.scss';

type ProjectSectionProps = {
  header: string;
  p: string;
};

export type GenericHeroProps = {
  title: string;
  descriptionText: string | JSX.Element;
  descriptionLight?: boolean;
  imgSrc?: string;
  imgAlt?: string;
  bgImg: string;
  pageDescription?: string;
  projectSection?: {
    duration: ProjectSectionProps;
    delivery: ProjectSectionProps;
  };
  paralaxTranslateY?: EffectNumber;
  externalLink?: string;
};

const GenericHero = ({
  title,
  descriptionText,
  descriptionLight,
  imgSrc,
  imgAlt,
  bgImg,
  pageDescription,
  projectSection,
  paralaxTranslateY,
  externalLink,
}: GenericHeroProps) => {
  return (
    <section className="generic-hero">
      <div className="generic-hero__background" aria-hidden="true">
        <ParallaxBanner
          layers={[
            {
              image: bgImg,
              speed: -15,
              scale: [1.1, 1.3, 'easeInOut'],
              expanded: false,
              translateY: paralaxTranslateY,
            },
          ]}
          className="generic-hero__background-image "
        />
      </div>
      <div className="generic-hero__wrapper">
        <div className={`generic-hero__content${!imgSrc && '-without-image'}`}>
          <div className="generic-hero__content--left">
            {pageDescription && (
              <span className="generic-hero__page-description">{pageDescription}</span>
            )}
            <h1 className="generic-hero__headline">
              {title}
              <br />
              <span className="generic-hero__headline-cursor">/</span>
            </h1>
            <p
              className={`generic-hero__body ${
                descriptionLight ? 'generic-hero__body--light' : ''
              }`}
            >
              {descriptionText}
            </p>
          </div>
          <div className="generic-hero__content--right">
            {projectSection ? (
              <div className="generic-hero__details">
                <div className="generic-hero__detail-item detail-item">
                  <h3 className="detail-item__title heading-3">{projectSection.delivery.header}</h3>
                  <p className="detail-item__description">{projectSection.delivery.p}</p>
                </div>
                <div className="generic-hero__detail-item detail-item">
                  <h3 className="detail-item__title heading-3">{projectSection.duration.header}</h3>
                  <p className="detail-item__description">{projectSection.duration.p}</p>
                </div>
                {externalLink ? (
                  <Button
                    className="generic-hero__details-btn"
                    tag="link"
                    href={externalLink}
                    target="_blank"
                  >
                    View Project
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        {imgSrc && (
          <div className="generic-hero__media">
            <img src={imgSrc} alt={imgAlt} className="generic-hero__media-image" loading="lazy" />
          </div>
        )}
      </div>
    </section>
  );
};

export default GenericHero;
